import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../Login/Login";
import Home from "../Home/Home";
import StaffHome from "../StaffHome/StaffHome";
import ClockInForm from "../ClockInForm/ClockInForm";
import ClockOutForm from "../ClockOutForm/ClockOutForm";
import VisitorClockInForm from "../VisitorClockInForm/VisitorClockInForm";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/clockin">
          <ClockInForm />
        </Route>
        <Route exact path="/clockout">
          <ClockOutForm />
        </Route>
        <Route exact path="/visitorclockin">
          <VisitorClockInForm />
        </Route>
        <Route exact path="/staffhome">
          <StaffHome />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
