import { Layout } from "antd";
import logo from "../../assets/logoo.png";
import "./PageLayout.css";

const { Header, Content, Footer } = Layout;

function PageLayout({ children }) {
  return (
    <Layout className="layout">
      <Header theme="light">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
      </Header>
      <Content style={{ padding: "10px 10px 0 10px" }}>
        <div className="site-layout-content">{children}</div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        ©2021 Aspiring Futures SA | All Rights Reserved | Powered by CareDiary
      </Footer>
    </Layout>
  );
}

export default PageLayout;
