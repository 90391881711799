import { useState, useEffect } from "react";
import { Form, Input, Button, Alert, Select } from "antd";
import {
  LockOutlined,
  LoginOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import PageLayout from "../PageLayout/PageLayout";
import WebcamCapture from "../Webcam/Webcam";
import { useHistory } from "react-router-dom";
import SuccessAlert from "../SuccessAlert/SuccessAlert";
import "./ClockInForm.css";
import store from "store";
import { BASE_URL } from "../../constants";
import moment from "moment";
import axios from "axios";

function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

function ClockInForm() {
  const history = useHistory();
  const token = store.get("auth-token");
  const location = store.get("location");

  const [image, setImage] = useState("");
  const [employees, setEmployees] = useState([]);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    fetch(`${BASE_URL}/employees?_limit=-1`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((results) => results.json())
      .then((data) => {
        setEmployees(data);
      });
  }, [token]);

  const onCancelClick = () => {
    history.push("/home");
  };

  const createEntry = async (checkInEmployee, values) => {
    const file = await urltoFile(image, "staff.jpeg", "image/jpeg");

    const time = moment().tz("Australia/Adelaide").format("HH:mm:ss");
    const id = checkInEmployee.id;
    const fullName = `${checkInEmployee.First_Name} ${checkInEmployee.Last_Name}`;
    const date = moment
      .tz(new Date(), "Australia/Adelaide")
      .format("YYYY-MM-DD");

    const formData = new FormData();

    formData.append("files", file);

    axios
      .post(`${BASE_URL}/upload`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const imageId = response.data[0].id;

        fetch(`${BASE_URL}/time-tracking-logs`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            Staff_Id: id,
            Staff_Name: fullName,
            Location: location,
            Date: date,
            Clock_In: time,
            Question_1: values.Question_1,
            Question_2: values.Question_2,
            Question_3: values.Question_3,
            Attachment: imageId,
          }),
        })
          .then((res) => res.json())
          .then((response) => {
            console.log("response", response);
          })
          .catch((error) => {
            console.log("error", error);
          });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onFinish = (values) => {
    setIsDisabled(true);
    const checkInEmployee = employees.find((employee) => {
      return employee.PIN === values.pin;
    });

    if (image === "") {
      setIsDisabled(false);
      setError({
        message: "Clock In Error",
        description: "Please take a selfie",
      });
      return;
    }

    if (checkInEmployee) {
      setError(null);
      createEntry(checkInEmployee, values);
      setSuccess({
        message: "Clock In Successful",
        description: `Hi ${checkInEmployee.First_Name}! Welcome to aspiring futures SA`,
      });
    } else {
      setIsDisabled(false);
      setError({
        message: "Clock In Failed",
        description: "Provided PIN don't belong to an Employee",
      });
    }
  };

  return (
    <PageLayout>
      <Button
        default
        size="large"
        htmlType="button"
        icon={<RollbackOutlined />}
        onClick={() => onCancelClick()}
      >
        Back
      </Button>

      <div className="checkin-content">
        <div></div>
        <div>
          <div className="title">Check In</div>
          <div className="checkin-form-container">
            <WebcamCapture image={image} setImage={setImage} />
            <Form
              layout="vertical"
              name="normal_login"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="pin"
                label="Please input your Pin"
                rules={[
                  {
                    required: true,
                    message: "Please input your Pin!",
                  },
                ]}
              >
                <Input
                  type="password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                />
              </Form.Item>

              <Form.Item
                name="Question_1"
                label="Have you recently returned from overseas or Interstate travel in the last 14 days?"
                rules={[
                  {
                    required: true,
                    message: "Please answer this question.",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Yes">Yes</Select.Option>
                  <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="Question_2"
                label="Have you been in contact with a person confirmed sick with COVID-19?"
                rules={[
                  {
                    required: true,
                    message: "Please answer this question.",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Yes">Yes</Select.Option>
                  <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="Question_3"
                label={`Do you have any of these symptoms: Fever, cough, runny nose, shortness of breath & other symptoms?`}
                rules={[
                  {
                    required: true,
                    message: "Please answer this question.",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Yes">Yes</Select.Option>
                  <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>
              {error && (
                <Alert
                  message={error.message}
                  description={error.description}
                  type="error"
                  showIcon
                />
              )}
              {success && <SuccessAlert success={success} />}
              <Form.Item>
                <Button
                  disabled={isDisabled}
                  className="clock-in-button"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  icon={<LoginOutlined />}
                  style={{ background: "#009fc6", borderColor: "#009fc6" }}
                >
                  Check In
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div></div>
      </div>
    </PageLayout>
  );
}

export default ClockInForm;
