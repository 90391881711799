import React from "react";
import { Button } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import Webcam from "react-webcam";
import "./Webcam.css";

const videoConstraints = {
  width: 350,
  height: 350,
  facingMode: "user",
};

const WebcamCapture = ({ image, setImage }) => {
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  }, [setImage]);

  return (
    <div className="webcam-container">
      <div className="webcam-img">
        {image === "" ? (
          <Webcam
            audio={false}
            height={350}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={350}
            videoConstraints={videoConstraints}
          />
        ) : (
          <img className="employee-pic" src={image} alt="Employee Pic" />
        )}
      </div>

      <div className="webcam-buttons">
        {image !== "" ? (
          <Button
            default
            htmlType="button"
            icon={<CameraOutlined />}
            onClick={() => setImage("")}
          >
            Retake
          </Button>
        ) : (
          <Button
            default
            htmlType="button"
            icon={<CameraOutlined />}
            onClick={() => capture()}
          >
            Capture
          </Button>
        )}
      </div>
    </div>
  );
};

export default WebcamCapture;
