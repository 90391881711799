import { Button } from "antd";
import PageLayout from "../PageLayout/PageLayout";
import Clock from "react-live-clock";
import { useHistory } from "react-router-dom";
import {
  LogoutOutlined,
  LoginOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import "./StaffHome.css";

const StaffHome = () => {
  const history = useHistory();

  const clockInClick = () => {
    history.push("/clockin");
  };

  const clockOutClick = () => {
    history.push("/clockout");
  };
  const onCancelClick = () => {
    history.push("/home");
  };

  return (
    <PageLayout>
      <Button
        default
        size="large"
        htmlType="button"
        icon={<RollbackOutlined />}
        onClick={() => onCancelClick()}
      >
        Back
      </Button>
      <div className="content">
        <div className="title">Staff Portal</div>
        <div className="clock">
          <Clock
            format={"h:mm:ss a"}
            ticking={true}
            timezone={"Australia/Adelaide"}
          />
        </div>

        <div className="actions">
          <Button
            size="large"
            icon={<LogoutOutlined />}
            onClick={() => clockOutClick()}
          >
            Check Out
          </Button>
          <Button
            type="primary"
            size="large"
            icon={<LoginOutlined />}
            onClick={() => clockInClick()}
            style={{ background: "#009fc6", borderColor: "#009fc6" }}
          >
            Check In
          </Button>
        </div>
      </div>
    </PageLayout>
  );
};

export default StaffHome;
