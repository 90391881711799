import { Form, Input, Button, Select, Alert } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import PageLayout from "../PageLayout/PageLayout";
import store from "store";
import { BASE_URL } from "../../constants";
import "./Login.css";

const { Option } = Select;

const Login = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  const onGenderChange = (value) => {
    form.setFieldsValue({ note: value });
  };

  const authenicate = (values) => {
    fetch(`${BASE_URL}/auth/local`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: `${values.username}`,
        password: `${values.password}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        store.set("auth-token", response.jwt);
        store.set("location", values.location);
        history.push("/home");
      })
      .catch(() => {
        setError({
          message: "Invalid Username or Password",
          description: "Please try again.",
        });
      });
  };

  const onFinish = (values) => {
    authenicate(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <PageLayout>
      <div className="login-form-container">
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 8,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="location"
            label="Location"
            rules={[
              {
                required: true,
                message: "Please select a location!",
              },
            ]}
          >
            <Select onChange={onGenderChange} allowClear>
              <Option value="Head Office">Head Office</Option>
              <Option value="44 Fairford Terrace">44 Fairford Terrace</Option>
              <Option value="32 Stirling Street">32 Stirling Street</Option>
              <Option value="18 Ralph Street">18 Ralph Street</Option>
              <Option value="6 Eton Street">6 Eton Street</Option>
              <Option value="106 OG Road">106 OG Road</Option>
              <Option value="38 Calomba Crescent">38 Calomba Crescent</Option>
              <Option value="26 Myall Boulevard">26 Myall Boulevard</Option>
              <Option value="24 Prince Charles St">24 Prince Charles St</Option>
              <Option value="20 Harold Road">20 Harold Road</Option>
              <Option value="27 Tobin Crescent">27 Tobin Crescent</Option>
              <Option value="23 Glenburnie Terrace">
                23 Glenburnie Terrace
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 8,
            }}
          >
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
      {error && (
        <Alert
          message={error.message}
          description={error.description}
          type="error"
          showIcon
        />
      )}
    </PageLayout>
  );
};

export default Login;
