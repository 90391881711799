import { useState, useEffect } from "react";
import { Form, Input, Button, Alert } from "antd";
import {
  LockOutlined,
  LoginOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import PageLayout from "../PageLayout/PageLayout";
import WebcamCapture from "../Webcam/Webcam";
import { useHistory } from "react-router-dom";
import SuccessAlert from "../SuccessAlert/SuccessAlert";
import "./ClockOutForm.css";
import store from "store";
import { BASE_URL } from "../../constants";
import moment from "moment";
import axios from "axios";

function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

function ClockOutForm() {
  const history = useHistory();
  const token = store.get("auth-token");

  const [image, setImage] = useState("");
  const [employees, setEmployees] = useState([]);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    fetch(`${BASE_URL}/employees?_limit=-1`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((results) => results.json())
      .then((data) => {
        setEmployees(data);
      });
  }, [token]);

  const onCancelClick = () => {
    history.push("/home");
  };

  const updateEntry = async (checkOutEmployee) => {
    const response = await fetch(
      `${BASE_URL}/time-tracking-logs?Staff_Id=${checkOutEmployee.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const allLogs = await response.json();

    let entry;
    if (allLogs && allLogs.length) {
      let sortedEntries = allLogs.sort(({ id: a }, { id: b }) => b - a);
      entry = sortedEntries[0];
    }

    if (entry && entry.Clock_Out === null) {
      const checkInDateTime = entry.Date + " " + entry.Clock_In;

      const checkoutDate = moment
        .tz(new Date(), "Australia/Adelaide")
        .format("YYYY-MM-DD");
      const checkOutTime = moment().format("HH:mm:ss");

      const checkOutDateTime = checkoutDate + " " + checkOutTime;

      var ms = moment(checkOutDateTime, "YYYY-MM-DD HH:mm:ss").diff(
        moment(checkInDateTime, "YYYY-MM-DD HH:mm:ss")
      );
      var d = moment.duration(ms);
      var totalTime = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

      const file = await urltoFile(image, "staff.jpeg", "image/jpeg");

      const formData = new FormData();

      formData.append("files", file);

      axios
        .post(`${BASE_URL}/upload`, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const imageId = response.data[0].id;

          let attachments = [...entry.Attachment];
          attachments.push(imageId);

          fetch(`${BASE_URL}/time-tracking-logs/${entry.id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              Clock_Out: checkOutTime,
              Total_Time: totalTime,
              Attachment: attachments,
            }),
          })
            .then((res) => res.json())
            .then((response) => {
              setSuccess({
                message: "Clock Out Successful",
                description: `See you later ${checkOutEmployee.First_Name}!`,
              });
            })
            .catch((error) => {
              console.log("error", error);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      setError({
        message: "Clock Out Error",
        description: "You need to Clock In first.",
      });
    }
  };

  const onFinish = (values) => {
    setIsDisabled(true);
    const checkOutEmployee = employees.find((employee) => {
      return employee.PIN === values.pin;
    });

    if (image === "") {
      setError({
        message: "Clock Out Error",
        description: "Please take a selfie",
      });
      return;
    }

    if (checkOutEmployee) {
      setError(null);
      updateEntry(checkOutEmployee);
    } else {
      setIsDisabled(false);
      setError({
        message: "Clock Out Failed",
        description: "Provided PIN don't belong to an Employee",
      });
    }
  };

  return (
    <PageLayout>
      <Button
        default
        size="large"
        htmlType="button"
        icon={<RollbackOutlined />}
        onClick={() => onCancelClick()}
      >
        Back
      </Button>

      <div className="checkout-content">
        <div className="title">Check Out</div>
        <div className="checkout-form-container">
          <WebcamCapture image={image} setImage={setImage} />
          <Form
            layout="vertical"
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="pin"
              label="Please input your Pin"
              rules={[
                {
                  required: true,
                  message: "Please input your Pin!",
                },
              ]}
            >
              <Input
                type="password"
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            {error && (
              <Alert
                message={error.message}
                description={error.description}
                type="error"
                showIcon
              />
            )}
            {success && <SuccessAlert success={success} />}
            <Form.Item>
              <Button
                disabled={isDisabled}
                className="check-out-button"
                type="primary"
                size="large"
                htmlType="submit"
                icon={<LoginOutlined />}
                style={{ background: "#e91e62", borderColor: "#e91e62" }}
              >
                Check Out
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </PageLayout>
  );
}

export default ClockOutForm;
