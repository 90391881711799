import { Button } from "antd";
import PageLayout from "../PageLayout/PageLayout";
import Clock from "react-live-clock";
import { useHistory } from "react-router-dom";
import { UserOutlined, SmileOutlined } from "@ant-design/icons";
import "./Home.css";

const Home = () => {
  const history = useHistory();

  const VisitorClockInClick = () => {
    history.push("/visitorclockin");
  };

  const StaffClick = () => {
    history.push("/staffhome");
  };

  return (
    <PageLayout>
      <div className="content">
        <div className="title">Welcome to aspiring futures SA!</div>
        <div className="clock">
          <Clock
            format={"h:mm:ss a"}
            ticking={true}
            timezone={"Australia/Adelaide"}
          />
        </div>
        <div className="actions">
          <Button
            type="primary"
            size="large"
            icon={<UserOutlined />}
            onClick={() => StaffClick()}
            style={{ background: "#009fc6", borderColor: "#009fc6" }}
          >
            Staff
          </Button>
          <Button
            type="primary"
            size="large"
            icon={<SmileOutlined />}
            onClick={() => VisitorClockInClick()}
            style={{ background: "#e91e62", borderColor: "#e91e62" }}
          >
            Visitor
          </Button>
        </div>
      </div>
    </PageLayout>
  );
};

export default Home;
