import { useState } from "react";
import { Form, Input, Button, Select } from "antd";
import {
  LoginOutlined,
  RollbackOutlined,
  UserOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import PageLayout from "../PageLayout/PageLayout";
import { useHistory } from "react-router-dom";
import SuccessAlert from "../SuccessAlert/SuccessAlert";
import "./VisitorClockInForm.css";
import store from "store";
import { BASE_URL } from "../../constants";
import moment from "moment";

function VisitorClockInForm() {
  const history = useHistory();
  const token = store.get("auth-token");
  const location = store.get("location");

  const [success, setSuccess] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const onCancelClick = () => {
    history.push("/home");
  };

  const createEntry = (values) => {
    setIsDisabled(true);
    const time = moment().tz("Australia/Adelaide").format("HH:mm:ss");
    const date = moment
      .tz(new Date(), "Australia/Adelaide")
      .format("YYYY-MM-DD");

    fetch(`${BASE_URL}/visitor-logs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        Visitor_Name: values.Visitor_Name,
        Visitor_Phone: values.Visitor_Phone,
        Location: location,
        Date: date,
        Check_In: time,
        Question_1: values.Question_1,
        Question_2: values.Question_2,
        Question_3: values.Question_3,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("response", response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onFinish = (values) => {
    createEntry(values);
    setSuccess({
      message: "Check In Successful",
      description: `Welcome to aspiring futures SA`,
    });
  };

  return (
    <PageLayout>
      <Button
        default
        size="large"
        htmlType="button"
        icon={<RollbackOutlined />}
        onClick={() => onCancelClick()}
      >
        Back
      </Button>

      <div className="checkin-content">
        <div></div>
        <div>
          <div className="title">Check In</div>
          <div className="checkin-form-container">
            <Form
              layout="vertical"
              name="normal_login"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="Visitor_Name"
                label="Full Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your full name!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                />
              </Form.Item>

              <Form.Item
                name="Visitor_Phone"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input
                  prefix={<PhoneOutlined className="site-form-item-icon" />}
                />
              </Form.Item>

              <Form.Item
                name="Question_1"
                label="Have you recently returned from overseas or Interstate travel in the last 14 days?"
                rules={[
                  {
                    required: true,
                    message: "Please answer this question.",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Yes">Yes</Select.Option>
                  <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="Question_2"
                label="Have you been in contact with a person confirmed sick with COVID-19?"
                rules={[
                  {
                    required: true,
                    message: "Please answer this question.",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Yes">Yes</Select.Option>
                  <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="Question_3"
                label={`Do you have any of these symptoms: Fever, cough, runny nose, shortness of breath & other symptoms?`}
                rules={[
                  {
                    required: true,
                    message: "Please answer this question.",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Yes">Yes</Select.Option>
                  <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>
              {success && <SuccessAlert success={success} />}
              <Form.Item>
                <Button
                  disabled={isDisabled}
                  className="clock-in-button"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  icon={<LoginOutlined />}
                  style={{ background: "#009fc6", borderColor: "#009fc6" }}
                >
                  Check In
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div></div>
      </div>
    </PageLayout>
  );
}

export default VisitorClockInForm;
