import { useState, useEffect } from "react";
import { Alert } from "antd";
import { useHistory } from "react-router-dom";

const SuccessAlert = ({ success }) => {
  const history = useHistory();
  const [show, setShow] = useState(true);

  // On componentDidMount set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false);
      history.push("/home");
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [history]);

  // If show is false the component will return null and stop here
  if (!show) {
    return null;
  }

  // If show is true this will be returned
  return (
    <Alert
      message={success.message}
      description={success.description}
      type="success"
      showIcon
    />
  );
};

export default SuccessAlert;
